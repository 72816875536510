import { captureException } from '@sentry/nextjs'
import Link from 'next/link'
import { useSearchParams } from 'next/navigation'
import { useRouter } from 'next/router'
import { useState } from 'react'

import Typography from '@thebeansgroup/ui/typography'

import Banner from '@components/banner'
import LoginAnimation from '@components/login_animation'
import LoginInput from '@components/login_input'
import PionButton from '@components/pion_button'
import {
  createTokenArray,
  logUserIntoPortal,
  navigateUserToChangePassword
} from '@helpers/auth'
import { getNextRoute } from '@helpers/routes'
import { useMutation } from '@hooks/use_mutation'

import {
  EMAIL_ERROR_BLANK,
  LOG_IN,
  PASSWORD_ERROR_BLANK,
  LOGIN_MUTATION,
  NEW_PASSWORD_MESSAGE,
  LOGIN_MUTATION_ERROR,
  RESET_PASSWORD,
  PASSWORD_RESET_TEXT
} from './constants'
import { getLoginVariables, hasInputErrors } from './helpers'
import * as styles from './styles'

const LoginForm = () => {
  const router = useRouter()
  const [e, setE] = useState('')
  const [p, setP] = useState('')
  const [inputErrors, setInputErrors] = useState({
    e: '',
    p: ''
  })
  const [loading, setLoading] = useState(false)
  const searchParams = useSearchParams()

  const [handleLoginMutation] = useMutation(LOGIN_MUTATION, {
    onCompleted: (data) => handleMutationSuccess(data),
    onError: (error) => handleMutationError(error)
  })

  const handleMutationError = (error) => {
    setLoading(false)
    setInputErrors({
      e: error.message,
      p: error.message
    })
    captureException(error)
  }

  const handleMutationSuccess = (data) => {
    if (data?.login?.authChallenge === NEW_PASSWORD_MESSAGE) {
      return navigateUserToChangePassword(data, e, router)
    } else if (data?.login?.accessToken) {
      const tokens = createTokenArray(data.login)
      return logUserIntoPortal(tokens, router)
    }

    setLoading(false)
    return setInputErrors({
      e: LOGIN_MUTATION_ERROR,
      p: LOGIN_MUTATION_ERROR
    })
  }

  const handleInputKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleLogin()
    }
  }

  const handleEChange = (value) => {
    setE(value)
    setInputErrors({
      ...inputErrors,
      e: ''
    })
  }

  const handlePChange = (value) => {
    setP(value)
    setInputErrors({
      ...inputErrors,
      p: ''
    })
  }

  const handleInputValidations = () => {
    if (!e || !p) {
      const errors = {
        e: e ? '' : EMAIL_ERROR_BLANK,
        p: p ? '' : PASSWORD_ERROR_BLANK
      }

      setInputErrors({ ...errors })
      return false
    }
    return true
  }

  const handleLogin = () => {
    if (handleInputValidations()) {
      setLoading(true)

      const variables = getLoginVariables(e, p)
      handleLoginMutation(variables)
    }
  }

  const navigateToResetPassword = () => {
    router.push(getNextRoute('resetPassword'))
  }

  const renderTitle = () => (
    <div css={styles.title}>
      <Typography
        variant='headline'
        component='h1'
        colour='grey-500'
      >
        {LOG_IN}
      </Typography>
    </div>
  )

  const renderButton = () => {
    return (
      <PionButton
        customStyles={styles.pionButton}
        loading={loading}
        onClick={handleLogin}
      >
        {LOG_IN}
      </PionButton>
    )
  }

  const renderEInput = () => {
    return (
      <LoginInput
        type='email'
        name='Email Address'
        id='email-address'
        value={e}
        onChange={handleEChange}
        error={inputErrors.e}
        handleKeyDown={handleInputKeyDown}
      />
    )
  }

  const renderPInput = () => {
    return (
      <LoginInput
        type='password'
        name='Password'
        id='password'
        value={p}
        onChange={handlePChange}
        error={inputErrors.p}
        handleKeyDown={handleInputKeyDown}
      />
    )
  }

  const renderResetPasswordButton = () => {
    return (
      <PionButton
        customStyles={styles.resetButton}
        onClick={navigateToResetPassword}
        type='secondary'
      >
        {RESET_PASSWORD}
      </PionButton>
    )
  }

  const renderResetPasswordBanner = () => {
    if (!searchParams.get('resetPassword')) return null

    return (
      <div css={styles.banner}>
        <Banner
          text={PASSWORD_RESET_TEXT}
          type='success'
        />
      </div>
    )
  }

  return (
    <div css={styles.loginFormHolder}>
      <div
        css={[
          styles.loginForm,
          hasInputErrors(inputErrors) && styles.loginError
        ]}
      >
        <LoginAnimation>
          {renderResetPasswordBanner()}
          {renderTitle()}
          <div css={styles.inputHolder}>
            {renderEInput()}
            {renderPInput()}
          </div>
          <div css={styles.button}>{renderButton()}</div>
          <div css={styles.tcs}>
            <Typography
              variant='caption1'
              colour='neutral-400'
            >
              By continuing to log in, you agree to
              <Link
                href={getNextRoute('loginTerms')}
                target='_blank'
              >
                {`Pion's Terms & Conditions`}
              </Link>
            </Typography>
          </div>
          {renderResetPasswordButton()}
        </LoginAnimation>
      </div>
    </div>
  )
}

export default LoginForm
