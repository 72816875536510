import { css } from '@emotion/react'

import Colours from '@thebeansgroup/ui_style_helpers/colours'
import { desktop } from '@thebeansgroup/ui_style_helpers/emotion_media_queries'

export const title = css`
  margin-bottom: 20px;
  align-self: flex-start;
`

export const loginFormHolder = css`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  padding: 0 20px;

  ${desktop} {
    padding: 0;
  }
`

export const loginForm = css`
  border: 1px solid ${Colours('grey', 100)};
  box-shadow: 0px 2px 4px 0px rgba(23, 27, 30, 0.1);
  border-radius: 10px;
  background: ${Colours('white')};
  padding: 40px 30px;

  ${desktop} {
    width: 440px;
  }
`

export const inputHolder = css`
  width: 100%;
  display: flex;
  flex-direction: column;
`

export const button = css`
  width: 100%;
  margin-top: 20px;
`

export const pionButton = css`
  width: 100%;
`

export const resetButton = css`
  width: 100%;
  margin-top: 16px;
`

export const tcs = css`
  margin-top: 16px;

  a {
    margin-left: 2px;
    color: ${Colours('colbalt', 500)};
  }
`

export const loginError = css`
  animation: shake 0.4s ease-in;

  @media (prefers-reduced-motion) {
    animation: none;
  }

  @keyframes shake {
    0% {
      transform: translateX(0);
    }
    20% {
      transform: translateX(-16px);
    }
    40% {
      transform: translateX(16px);
    }
    60% {
      transform: translateX(-8px);
    }
    80% {
      transform: translateX(8px);
    }
    100% {
      transform: translateX(0);
    }
  }
`

export const banner = css`
  margin-bottom: 20px;
`
