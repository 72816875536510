import { gql } from '@apollo/client'

export const LOG_IN = 'Log in'
export const RESET_PASSWORD = 'Reset Password'
export const EMAIL_ERROR_BLANK = 'Email field cannot be empty'
export const PASSWORD_ERROR_BLANK = 'Password field cannot be empty'
export const USER_CLASS = 'BUSINESS'
export const NEW_PASSWORD_MESSAGE = 'NEW_PASSWORD_REQUIRED'
export const LOGIN_MUTATION_ERROR = 'There was an error, please try again'

export const LOGIN_MUTATION = gql`
  mutation Login($loginInput: LoginInput!) {
    login(input: $loginInput) {
      accessToken
      authChallenge
      idToken
      refreshToken
      sessionToken
    }
  }
`

export const PASSWORD_RESET_TEXT = 'Password updated. Please sign in.'
