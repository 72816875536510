import { USER_CLASS } from './constants'

export const hasInputErrors = (inputErrors) =>
  !!inputErrors.e || !!inputErrors.p
export const getLoginVariables = (email, password) => {
  const variables = {
    loginInput: {
      email,
      password,
      userClass: USER_CLASS
    }
  }

  return { variables }
}
