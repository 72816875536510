import LoginForm from '@components/login_form'
import LoginPage from '@components/login_page'

const Login = () => (
  <LoginPage>
    <LoginForm />
  </LoginPage>
)

export default Login
